import React, { useState } from "react"
import Img from "gatsby-image"
import * as util from "src/util"

function FluidImage({ fluid, alt = "" }) {
  const [imageStyles, setImageStyles] = useState({ opacity: 0 })
  if (!fluid) {
    return null
  }
  return (
    <Img
      fluid={fluid}
      style={{ width: "100%", ...imageStyles }}
      loading="eager"
      alt={alt}
      fadeIn={false}
      onLoad={() => {
        setImageStyles({ opacity: 1 })
      }}
      onError={(e) => {
        util.logError(e)
      }}
    />
  )
}

export default FluidImage
