const _get = require("lodash/get")
const { trackCustomEvent } = require("gatsby-plugin-google-analytics")

module.exports.formatSlug = function formatSlug(post, baseURL) {
  if (post.status === "draft") {
    return `/drafts/${post.wordpress_id}/`
  }
  let base = baseURL || "/"
  if (!base.startsWith("/")) {
    base = `/${base}`
  }
  if (!base.endsWith("/")) {
    base = `${base}/`
  }
  return `${base}${post.slug}/`
}

module.exports.computePostLink = function computePostLink(post, baseURL) {
  return post.status === "draft"
    ? `/drafts/${post.wordpress_id}/`
    : `/${baseURL ? baseURL + "/" : ""}${post.slug}/`
}

/**
 * Compute Featured Media image attributes (url + alt_text)
 */
module.exports.computeImageAttr = function computeImageAttr(
  post,
  size = "large"
) {
  const possibleSizes = ["medium", "large"]
  if (!post) {
    return [null, ""]
  }
  if (possibleSizes.indexOf(size) === -1) {
    throw new Error("Incorrect size passed to computerImageUrl")
  }
  return [
    _get(post, `featured_media.${size}.childImageSharp.fluid`),
    _get(post, "featured_media.alt_text", ""),
  ]
}

/**
 * Compute ACF image attributes (url + alt_text)
 */
module.exports.computeAcfImageAttr = function computeAcfImageAttr(
  el,
  size = "large"
) {
  const possibleSizes = ["medium", "large"]
  if (!el) {
    return [null, ""]
  }
  if (possibleSizes.indexOf(size) === -1) {
    throw new Error("Incorrect size passed to computerImageUrl")
  }
  return [_get(el, `${size}.childImageSharp.fluid`), _get(el, "alt_text")]
}

/**
 * Replace Image Base
 */
function replaceImageBase(url, prefix) {
  if (!url) {
    return url
  }
  let replaceURL = "/assets"
  if (process.env.BRANCH === "local") {
    replaceURL = process.env.S3_URL
  }
  if (prefix && process.env.BRANCH !== "local") {
    replaceURL = prefix + replaceURL
  }
  return url
    .replace(
      /(https?:\/\/)?internal-wp\.smokescreen\.io\/wp-content/g,
      replaceURL
    )
    .replace(/(https?:\/\/)?3\.6\.63\.223\/wp-content/g, replaceURL)
}
module.exports.replaceImageBase = replaceImageBase

function replaceImageSEOBase(url, prefix) {
  if (!url) {
    return url
  }
  const replaceURL = prefix ? `${prefix}/assets` : "/assets"
  return url
    .replace(
      /(https?:\/\/)?internal-wp\.smokescreen\.io\/wp-content/g,
      replaceURL
    )
    .replace(/(https?:\/\/)?3\.6\.63\.223\/wp-content/g, replaceURL)
    .replace(
      /(https?:\/\/)?ss-website-content-prod\.s3\.ap-south-1\.amazonaws\.com\/wp-content/g,
      replaceURL
    )
}

module.exports.replaceImageSEOBase = replaceImageSEOBase

/**
 * Track event
 */
module.exports.trackEvent = (category, action) => {
  trackCustomEvent(category, action)
}

/**
 * Log Error
 */
module.exports.logError = (error, extra) => {
  try {
    if (process.env.NODE_ENV === "development" || !window.Sentry) {
      console.log(
        "Log Error: ",
        typeof error === "object" ? JSON.stringify(error) : error
      )
      return
    }
    if (typeof extra !== "object") {
      window.Sentry.captureException(error)
      return
    }
    // NOTE: The values contained in the tags and extra objects must be scalar values (e.g., booleans, numbers, or strings).
    // Link: https://docs.logrocket.com/reference#captureexception
    // Right now we aren't checking for it here. (Sarthak)
    window.Sentry.withScope((scope) => {
      Object.keys(extra).forEach((key) => {
        scope.setExtra(key, extra[key])
      })
      window.Sentry.captureException(error)
    })
  } catch (e) {
    console.error(e)
  }
}

module.exports.compileArticleSEOFields = function compileArticleSEOFields(
  post,
  siteUrl
) {
  if (!post) {
    return []
  }
  let ogImage =
    _get(post, "yoast_meta.yoast_wpseo_opengraph_image.source_url") ||
    _get(post, "featured_media.source_url")
  const siteUrlWithoutForwardSlash = siteUrl.replace(/\/$/g, "")
  let otherMetaTags = [
    {
      property: "article:published_time",
      content: post.date,
    },
    {
      property: "article:modified_time",
      content: post.modified,
    },
    {
      property: "og:updated_time",
      content: post.modified,
    },
    {
      property: "og:image",
      content: replaceImageSEOBase(ogImage, siteUrlWithoutForwardSlash),
    },
    {
      property: "twitter:image",
      content: replaceImageSEOBase(ogImage, siteUrlWithoutForwardSlash),
    },
    {
      property: "twitter:card",
      content: "summary_large_image",
    },
    {
      property: "og:image:width",
      content: _get(
        post,
        "yoast_meta.yoast_wpseo_opengraph_image.media_details.width"
      ),
    },
    {
      property: "og:image:height",
      content: _get(
        post,
        "yoast_meta.yoast_wpseo_opengraph_image.media_details.height"
      ),
    },
  ]
  const tags = post.tags || []
  tags.forEach((tag) => {
    otherMetaTags.push({
      property: "article:tag",
      content: tag.name,
    })
  })
  return otherMetaTags
}

module.exports.smoothScrollToHash = (id) => {
  const el = document.querySelector(id)
  if (!el) {
    return
  }
  // 20 = buffer to not have the element right on top
  const top = el.getBoundingClientRect().top - 20
  window.scroll({ top: window.scrollY + top, behavior: "smooth" })
}
