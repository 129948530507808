// Remember to add a trialing slash as sitemap has trailing slashes as the end
const routes = {
  pricing: "/pricing/",
  home: "/",
  contact: "/contact/",
  about: "/about/",
  demo: "/demo/",
  external: {
    twitter: "https://twitter.com/smokescreentech",
    linkedin:
      "https://in.linkedin.com/company/smokescreen-technologies-pvt--ltd-",
  },
  blog: "/blog/",
  resources: "/all-resources/",
  responsible_disclosure_policy: "/responsible-disclosure-policy/",
  privacy_policy: "/privacy-policy/",
  library: "/library/",
  red_team_chronicles: "/library/red-team-chronicles/",
  from_the_lab: "/library/from-the-lab/",
  unfiltered: "/library/unfiltered/",
  siege_craft: "/library/siege-craft/",
  shadowhunt_intel: "/library/shadowhunt-intel/",
  handbooks: "/library/handbooks/",
  register_deal: "/register-deal/",
}

// Remember to add a trialing slash as sitemap has trailing slashes as the end - TODO: automate this
const useCaseRoutes = {
  web_application_attacks: "/web-application-attacks/",
  lateral_movement: "/lateral-movement-detection/",
  ransomware: "/ransomware/",
  targeted_threats: "/targeted-threat-detection/",
  social_enginerring: "/social-engineering/",
  malware_less_attacks: "/malware-less-attacks/",
}

// Used in gatsby-node
module.exports = routes
module.exports.useCaseRoutes = useCaseRoutes
