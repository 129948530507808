import { css } from "@emotion/core"

import { Link } from "gatsby"
import Img from "gatsby-image"
import _get from "lodash/get"
import React from "react"
import { If } from "components"
import * as util from "src/util"
import routes from "src/constants/routes"

const postWrapper = css`
  padding: 10px;
  margin-bottom: 0;
  @media (max-width: 576px) {
    padding: 0;
  }
`

const postElement = css`
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  width: 100%;
  max-width: 552px;
  height: 325px;
  position: relative;
  display: block;
  background: gray;

  @media (max-width: 576px) {
    height: 275px;
  }
  @media (max-width: 650px) {
    margin-bottom: 20px !important;
  }
`

const imageWrapper = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`

const content = css`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 17px 38px;
  @media (max-width: 576px) {
    padding: 1rem;
  }
`

const title = css`
  font-family: var(--heading);
  color: white;
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.35;
  margin-bottom: 10px;
  @media (max-width: 576px) {
    font-size: 1.25rem;
  }
`

const byline = css`
  color: white;
  font-size: 18px;
  line-height: 1.78;
  @media (max-width: 576px) {
    font-size: 1rem;
  }
`

export default function PostCard({ post }) {
  if (!post) {
    return null
  }
  const [imageUrl, altText] = util.computeImageAttr(post)
  const link = `${routes.red_team_chronicles}${post.slug}/`
  return (
    <li css={postWrapper}>
      <Link to={link} css={postElement}>
        <div css={imageWrapper}>
          <If test={imageUrl}>
            <Img fluid={imageUrl} alt={altText} />
          </If>
        </div>
        <div css={content}>
          <h2 css={title}>{post.title}</h2>
          <If test={_get(post, "author.name")}>
            <div css={byline}>By {_get(post, "author.name")}</div>
          </If>
        </div>
      </Link>
    </li>
  )
}
