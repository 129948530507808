import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import { routes } from "src/constants"
import { colors } from "css/theme"
import { useCaseRoutes } from "src/constants/routes"

import Logo from "images/favicon-48.png"

const wrapper = css`
  background-color: ${colors.footerBackground};
  color: ${colors.footerColor};
`

const content = css`
  max-width: 1125px;
  padding: 4rem 0 3rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  @media (max-width: 1199px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
    padding-top: 3rem;
  }
`

const aboutSection = css`
  margin-right: 3.5rem;
  @media (max-width: 1023px) {
    margin-bottom: 2rem;
  }
`

const logo = css`
  max-height: 48px;
  margin-bottom: 20px;
  flex-shrink: 0;
  margin-left: -8px;
  margin-top: -4px;
  display: block;
  @media (max-width: 479px) {
    margin-bottom: 1rem;
  }
`
const logoImg = css`
  max-height: inherit;
`

const about = css`
  line-height: 1.86;
  color: ${colors.headerColor};
  margin-bottom: 20px;
  max-width: 350px;
  font-size: 14px;
`

const copyright = css`
  font-size: 12px;
  color: #bababa;
`

const linksSection = css`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  @media (max-width: 767px) {
    flex-wrap: wrap;
    margin-bottom: -1rem;
  }
`

const group = css`
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 50%;
    margin-bottom: 2rem;
  }
  @media (max-width: 457px) {
    width: 100%;
  }
`
const heading = css`
  font-family: var(--heading);
  font-size: 12px;
  color: white;
  letter-spacing: 0.67px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
`
const link = css`
  font-size: 14px;
  line-height: 1.29;
  color: ${colors.headerColor};
  margin-bottom: 16px;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: white;
    outline: none;
  }
`

function Footer() {
  return (
    <footer css={wrapper}>
      <div css={content}>
        <div css={aboutSection}>
          <Link to={routes.home} css={logo}>
            <img src={Logo} alt="Go to home" css={logoImg} />
          </Link>
          <p css={about}>
            Simple solutions for detecting and containing threats. Working with
            us does not break the bank or your spirit. We’re the company of
            choice for offensive security teams with a Net Promoter Score of
            70+.
          </p>
          <p css={copyright}>© 2015-2021 Smokescreen. All rights reserved.</p>
        </div>
        <div css={linksSection}>
          <div css={group}>
            <div css={heading}>Solutions For</div>
            <Link to={useCaseRoutes.web_application_attacks} css={link}>
              Web Application Attacks
            </Link>
            <Link to={useCaseRoutes.lateral_movement} css={link}>
              Lateral Movement
            </Link>
            <Link to={useCaseRoutes.ransomware} css={link}>
              Ransomware Attacks
            </Link>
            <Link to={useCaseRoutes.targeted_threats} css={link}>
              Targeted Threats
            </Link>
            <Link to={useCaseRoutes.social_enginerring} css={link}>
              Social Engineering
            </Link>
            <Link to={useCaseRoutes.malware_less_attacks} css={link}>
              Malware-less Attacks
            </Link>
          </div>
          <div css={group}>
            <div css={heading}>Resources</div>
            <Link to={routes.demo} css={link}>
              Get a demo
            </Link>
            <Link to={routes.pricing} css={link}>
              Pricing
            </Link>
            <Link to={routes.library} css={link}>
              The Library
            </Link>
            <Link to={routes.resources} css={link}>
              Resources
            </Link>
            <Link to={routes.blog} css={link}>
              Blog
            </Link>
          </div>
          <div css={group}>
            <div css={heading}>Company</div>
            <Link to={routes.about} css={link}>
              About us
            </Link>
            <Link to={routes.contact} css={link}>
              Contact us
            </Link>
            <Link to={routes.register_deal} css={link}>
              Register a Deal
            </Link>
            <a
              href={routes.external.linkedin}
              css={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Linkedin
            </a>
            <a
              href={routes.external.twitter}
              css={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </div>
          <div css={group}>
            <div css={heading}>Legal</div>
            <Link to={routes.responsible_disclosure_policy} css={link}>
              Responsible Disclosure
            </Link>
            <a href="/mgt7.pdf" css={link} target="_blank">
              Form MGT-7
            </a>
            <Link to={routes.privacy_policy} css={link}>
              Privacy Policy
            </Link>
            <a href="/csr-policy.pdf" css={link} target="_blank">
              CSR Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
