const resourceTypes = {
  WHITEPAPER: "whitepaper",
  CASE_STUDY: "case-study",
  COMPARISON: "comparison",
  SOLUTION: "solution",
}

const resourceTypeProperNames = {
  [resourceTypes.WHITEPAPER]: "Whitepaper",
  [resourceTypes.CASE_STUDY]: "Case Study",
  [resourceTypes.COMPARISON]: "Competitive Comparison",
  [resourceTypes.SOLUTION]: "Industry Solution",
}

const resourceTypeProperNamesPlural = {
  WHITEPAPER: "Whitepapers",
  CASE_STUDY: "Case Studies",
  COMPARISON: "Competitive Comparisons",
  SOLUTION: "Industry Solutions",
}

export { resourceTypes, resourceTypeProperNames, resourceTypeProperNamesPlural }
