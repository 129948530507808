const colors = {
  background: "#082128",
  darkBackground: "#001F24",
  brighterBackground: "#072429",
  brightestBackgorund: "#093B43",
  brightBlue: "#16D9F3",
  orange: "#FF644E",
  darkOrange: "#FF5640",
  green: "#6CCB0E",
  lightGreen: "#76be2f",
  grayBorder: "#979797",
  dullBlack: "#1D2323", // remove
  costSectionBlue: "#54CDDD",
  costSectionOrange: "#C56557",
  gray: "#809196",
  gradientStartColor: "#01262C",
  grayBackground: "#3e4e54",
  error: "#dd4a68", // get better color
  featureGroupBackground: "#191919", // remove
  liveChatBackground: "#2C2C2C",
  liveChatColor: "#C1C1C1",
  footerBackground: "#222222",
  footerColor: "#818181",
  headerBackground: "#161D1E",
  headerColor: "#C5C5C5", // make consistent
  black15: "rgba(0, 0, 0, 0.15)",
  black42: "rgba(0,0,0,.42)", // remove
  black50: "rgba(0,0,0,.5)",
  white10: "rgba(255,255,255,.1)",
  white70: "rgba(255,255,255,.7)", // make consistent
  inputBackground: "rgba(94,94,94,.59)",
  modalWrapperBackground: "rgba(0, 31, 36,.75)",
  addonLabelBackground: "rgba(210,96,80,.3)", // remove,
  modalBackground: "#052024",
  articleByline: "#5e5e5e",
  postBackground: "#efefef",
  socialLinkColor: "#a8a9b0",
  bannerCostSectionBlue: "#96ecf8",
  bannerCostSectionOrange: "#f1bfb8",
  blockquoteText: "hsla(0,0%,0%,0.59)",
  blockquoteBorder: "hsla(0,0%,0%,0.2)",
  dropdownHover: "#252525",
  skyblue: "#00a2bf",
}

const radius = {
  default: "4px",
  medium: "8px",
  big: "12px",
}

export { colors, radius }
