import React from "react"
import { css } from "@emotion/core"

import { useSiteMetadata } from "../hooks/use-site-metadata"
import { colors } from "css/theme"
import { If } from "src/components"

const draftLabel = css`
  color: ${colors.orange} !important;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-family: var(--heading);
`

function DraftLabel({ status, style = {} }) {
  const { branch } = useSiteMetadata()
  const showDrafts = ["develop", "local"].indexOf(branch) !== -1
  return (
    <If test={showDrafts && status === "draft"}>
      <div css={draftLabel} style={style}>
        Draft
      </div>
    </If>
  )
}

export default DraftLabel
