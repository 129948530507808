/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import _get from "lodash/get"
import { useSiteMetadata } from "../hooks/use-site-metadata"

function SEO({
  description,
  lang,
  meta,
  title,
  yoastMeta,
  ogType,
  image,
  extra = [],
  appendSeparator,
}) {
  const siteMetadata = useSiteMetadata()
  const { pathname } = useLocation()
  const { siteUrl, branch } = siteMetadata
  const metaDescription =
    _get(yoastMeta, "yoast_wpseo_metadesc") ||
    description ||
    siteMetadata.description
  const metaTitle = _get(yoastMeta, "yoast_wpseo_title") || title
  const ogTitle = _get(yoastMeta, "yoast_wpseo_opengraph_title") || metaTitle
  const ogDescription =
    _get(yoastMeta, "yoast_wpseo_opengraph_description") || metaDescription
  const twitterTitle = _get(yoastMeta, "yoast_wpseo_twitter_title") || metaTitle
  const twitterDescription =
    _get(yoastMeta, "yoast_wpseo_twitter_description") || metaDescription
  //   yoast_wpseo_redirect
  //   yoast_wpseo_metakeywords
  //   yoast_wpseo_meta_robots_noindex
  //   yoast_wpseo_meta_robots_nofollow
  //   yoast_wpseo_meta_robots_adv
  //   yoast_wpseo_linkdex
  //   yoast_wpseo_focuskw
  //   yoast_wpseo_canonical
  //   yoast_wpseo_twitter_image {
  //     source_url
  //   }
  //   yoast_wpseo_opengraph_image {
  //     source_url
  //   }
  let imageUrl = image
  if (imageUrl && imageUrl.indexOf("/") === 0) {
    imageUrl = `${siteUrl}${imageUrl}`
  }
  // Can take this as an option in compileArticleSEOFields calls if there is a page that does not need to be indexed
  const isIndexed = branch === "master"
  const optionalMeta = []
  if (image) {
    optionalMeta.push({
      property: "og:image",
      content: imageUrl,
    })
    optionalMeta.push({
      property: "twitter:image",
      content: imageUrl,
    })
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      // TODO: Handle titleTemplate when yoastMeta has title
      titleTemplate={
        appendSeparator !== false ? `%s | ${siteMetadata.title}` : `%s`
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: "og:site_name",
          content: siteMetadata.title,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: "og:locale",
          content: "en_US",
        },
        {
          property: `og:type`,
          content: ogType || `website`,
        },
        {
          property: "og:url",
          content: `${siteUrl}${pathname}`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: twitterTitle,
        },
        {
          name: `twitter:description`,
          content: twitterDescription,
        },
        {
          name: "robots",
          content: isIndexed ? "index,follow,archive" : "noindex",
        },
      ]
        .concat(meta)
        .concat(optionalMeta)
        .concat(extra)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
