import { css } from "@emotion/core"
import React from "react"
import { DraftLabel, FluidImage } from "src/components"
import * as util from "src/util"
import "src/queries/featured_media"
import { ResourceType } from "./Labels"
import { Link } from "gatsby"

const postWidth = 336
const postGutterRight = 45

const postWrapper = css`
  list-style-type: none;
  margin-bottom: 80px;
  overflow: hidden;
  color: white;
  text-decoration: none;
  display: flex;
  &:hover * {
    text-decoration: none;
  }
  * {
    text-decoration: none;
    color: white;
  }
  @media (max-width: 479px) {
    margin-bottom: 2.5rem;
    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
  width: ${postWidth}px;
  margin-right: ${postGutterRight}px;
  &:nth-of-type(3n) {
    margin-right: 0;
  }
  @media (max-width: 1150px) {
    &:nth-of-type(3n) {
      margin-right: ${postGutterRight}px;
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
  @media (max-width: 767px) {
    &:nth-of-type(n) {
      margin-right: auto;
      margin-left: auto;
    }
    max-width: 100%;
  }
`

const postCSS = css``

const title = css`
  font-family: var(--heading);
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 1.5rem;
  line-height: 1.65;
  max-height: 152px; /* 37.95px * 4 */
  overflow: hidden;
  padding-right: 1rem;
  @media (max-width: 479px) {
    font-size: 19px;
    line-height: 30px;
    max-height: 120px;
  }
`

const paddedContext = css`
  padding-left: 1rem;
`

const excerpt = css`
  opacity: 0.7;
  font-size: 18px;
  line-height: 1.56;
  overflow: hidden;
  padding-right: 1rem;
  max-height: 196px; // 28 * 7 hack todo: add proper clamping code
  p {
    margin-bottom: 1rem;
  }
  br {
    display: none;
  }
  @media (max-width: 479px) {
    font-size: 1rem;
    line-height: 1.5;
    max-height: 168px;
  }
`

// These are CSS hacks above 768px to make the resource card image
// fit in a particular aspect ratio.
// The reason for this is that right now we have different kinds of images on the resource
// page and that might not be standardized for a while.
const imageAspectRatio = css`
  @media (min-width: 768px) {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 126%;
  }
  position: relative;
`

const image = css`
  margin-bottom: 1.5rem;
  max-width: 100%;
  @media (min-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .gatsby-image-wrapper img {
      object-fit: contain !important;
      object-position: center;
    }
  }
`

function ResourceItem({ resource }) {
  const post = resource
  const [imageUrl, altText] = util.computeImageAttr(post, "medium")
  return (
    <li key={post.slug} css={postWrapper}>
      <Link to={util.computePostLink(post, "resources")} css={postCSS}>
        <div css={imageAspectRatio}>
          <div css={image}>
            <FluidImage fluid={imageUrl} alt={altText} />
          </div>
        </div>
        <div css={paddedContext}>
          <ResourceType type={post.acf.type} />
          <div css={title}>{post.title}</div>
          <DraftLabel status={post.status} />
          <div css={excerpt}>{post.excerpt}</div>
        </div>
      </Link>
    </li>
  )
}

export default ResourceItem
