import { css } from "@emotion/core"
import Img from "gatsby-image"
import { colors, radius } from "css/theme"
import { Link } from "gatsby"
import _get from "lodash/get"
import React from "react"
import { If } from "src/components"
import * as util from "src/util"
import DraftLabel from "../components/DraftLabel"

const postWidth = 336
const postGutterRight = 45

const _postWrapper = css`
  list-style-type: none;
  margin-bottom: 54px;
  border-radius: ${radius.big};
  overflow: hidden;
  color: white;
  text-decoration: none;
  display: flex;
  * {
    text-decoration: none;
    color: white;
  }
  @media (max-width: 479px) {
    margin-bottom: 2.5rem;
  }
`

// TODO: reduce number of css classes by combining them

const postWrapper = [
  _postWrapper,
  css`
    width: ${postWidth}px;
    height: 630px;
    margin-right: ${postGutterRight}px;
    // first one is featured so 3n + 1
    @media (min-width: 1151px) {
      &:nth-of-type(3n + 1) {
        margin-right: 0;
      }
    }
    @media (min-width: 992px) and (max-width: 1150px) {
      &:nth-of-type(2n + 1) {
        margin-right: 0;
      }
    }
    @media (max-width: 991px) {
      width: 100%;
      height: auto;
      margin-right: auto;
      max-width: 600px;
      margin-left: auto;
    }
  `,
]

const bigPostWrapper = [
  postWrapper,
  css`
    @media (min-width: 992px) {
      width: 100%;
      height: 444px;
    }
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    margin-top: -280px;
    @media (min-width: 992px) and (max-width: 1150px) {
      margin-left: -100px;
      margin-right: -100px !important;
      width: calc(100% + 200px);
    }
  `,
]

const _postCSS = css`
  display: flex;
  flex-grow: 1;
  &:hover,
  *:hover {
    color: white;
    text-decoration: none;
  }
`

const postCSS = [
  _postCSS,
  css`
    flex-direction: column;
  `,
]

const bigPostCSS = [
  postCSS,
  css`
    @media (min-width: 992px) {
      flex-direction: row;
    }
  `,
]

const _picWrapper = css`
  position: relative;
  display: block;
  flex-shrink: 0;
`

const picWrapper = [
  _picWrapper,
  css`
    width: 100%;
    /*padding-bottom: 65.035%;*/
    padding-bottom: 60.061%;
  `,
]

const bigPicWrapper = [
  picWrapper,
  css`
    @media (min-width: 992px) {
      width: 50%;
      height: 100%;
      padding-bottom: 0;
    }
  `,
]

const _image = css`
  position: absolute;
  top: -1px; /* Hack to not have weird borders around images */
  bottom: -1px;
  right: -1px;
  left: -1px;
  overflow: hidden;
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`

const image = [
  _image,
  css`
    border-top-left-radius: ${radius.big};
    border-top-right-radius: ${radius.big};
  `,
]

const bigImage = [
  image,
  css`
    @media (min-width: 992px) {
      border-top-right-radius: 0;
      border-top-left-radius: ${radius.big};
      border-bottom-left-radius: ${radius.big};
    }
  `,
]

const contentBackground = css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${colors.grayBackground};
  overflow: hidden;
`

const _contentWrapper = css`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const contentWrapper = [
  _contentWrapper,
  css`
    padding: 20px;
    @media (min-width: 992px) {
      margin-bottom: 30px !important;
      padding: 30px 20px 0; /* to have a gap in the bottom */
    }
  `,
]

const bigContentWrapper = [
  contentWrapper,
  css`
    @media (min-width: 992px) {
      padding: 60px 53px 0;
      margin-bottom: 30px !important;
    }
  `,
]

const title = css`
  font-family: var(--heading);
  font-size: 23px;
  margin-bottom: 16px;
  line-height: 34px;
  max-height: 136px; /* 34px * 4 */
  overflow: hidden;
  @media (max-width: 479px) {
    font-size: 19px;
    line-height: 30px;
    max-height: 120px;
  }
`

const description = css`
  opacity: 0.7;
  font-size: 18px;
  line-height: 28px;
  overflow: hidden;
  max-height: 196px; // 28 * 7 hack todo: add proper clamping code
  p {
    margin-bottom: 1rem;
  }
  br {
    display: none;
  }
  @media (max-width: 479px) {
    font-size: 1rem;
    line-height: 1.5;
    max-height: 168px;
  }
`

const byline = css`
  text-transform: uppercase;
  color: white;
  font-family: var(--heading);
  letter-spacing: 0.67px;
  font-size: 12px;
  margin-top: auto;
  @media (max-width: 991px) {
    margin-top: 1rem;
  }
`

const BlogPostSticky = ({ post }) => {
  if (!post) {
    return null
  }
  const cssClasses = {
    postWrapper: bigPostWrapper,
    post: bigPostCSS,
    picWrapper: bigPicWrapper,
    contentWrapper: bigContentWrapper,
    image: bigImage,
  }
  const [imageUrl, altText] = util.computeImageAttr(post)
  const link = util.computePostLink(post)
  return (
    <div key={link} css={cssClasses.postWrapper}>
      <Link to={link} css={cssClasses.post}>
        <div css={cssClasses.picWrapper}>
          <div
            css={[
              cssClasses.image,
              css`
                background-color: ${imageUrl
                  ? "transparent"
                  : colors.postBackground};
              `,
            ]}
          >
            <If test={imageUrl}>
              <Img
                fluid={imageUrl}
                alt={altText}
                loading="eager"
                fadeIn={false}
              />
            </If>
          </div>
        </div>
        <div css={contentBackground}>
          <div css={cssClasses.contentWrapper}>
            <DraftLabel status={post.status} />
            <h2 css={title}>{post.title}</h2>
            <div css={description}>{post.excerpt}</div>
            <If test={_get(post, "author.name")}>
              <div css={byline}>By {_get(post, "author.name")}</div>
            </If>
          </div>
        </div>
      </Link>
    </div>
  )
}
export default BlogPostSticky
