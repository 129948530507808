import React from "react"

function If({ test, children }) {
  if (test) {
    return <>{children}</>
  }
  return null
}

export default If
