import { css } from "@emotion/core"

import { colors } from "./theme"

const h2 = css`
  font-family: var(--heading);
  color: white;
  font-weight: bold;
  font-size: 42px;
  margin-bottom: 0;
  line-height: 1.33;
  @media (max-width: 767px) {
    font-size: 28px;
  }
`

const h3 = css`
  color: white;
  font-family: var(--heading);
  font-size: 1.75rem;
  line-height: 2;
  font-weight: bold;
  @media (max-width: 767px) {
    font-size: 1.25rem;
    line-height: 1.5;
  }
`

const btn = css`
  display: inline-block;
  font-family: var(--heading);
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  padding: 10px 3rem;
  border-radius: 4px;
  box-shadow: none;
  text-decoration: none;
  text-align: center;
  border: 0;
  cursor: pointer;
  text-transform: uppercase;
  @media (max-width: 479px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  &:hover {
    text-decoration: none;
  }
`

const loadingBtn = [
  btn,
  css`
    position: relative;
    cursor: default;
    text-shadow: none !important;
    color: transparent !important;
    opacity: 1;
    pointer-events: none;
    user-select: none;
    transition: all 0s linear, opacity 0.1s ease;
    font-size: 1rem;
    @keyframes button-spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      margin: -0.64285714em 0em 0em -0.64285714em;
      width: 1.28571429em;
      height: 1.28571429em;
      border-radius: 500rem;
      border: 0.2em solid ${colors.black15};
    }
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      margin: -0.64285714em 0em 0em -0.64285714em;
      width: 1.28571429em;
      height: 1.28571429em;
      animation: button-spin 0.6s linear;
      animation-iteration-count: infinite;
      border-radius: 500rem;
      border-color: #ffffff transparent transparent;
      border-style: solid;
      border-width: 0.2em;
      box-shadow: 0px 0px 0px 1px transparent;
    }
  `,
]

export { h2, h3, btn, loadingBtn }
