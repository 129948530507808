import React from "react"
import { css } from "@emotion/core"

import { colors } from "css/theme"
import { resourceTypeProperNames } from "../../constants/resourceTypes"

const label = css`
  color: ${colors.orange} !important;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-family: var(--heading);
  font-size: 14px;
  letter-spacing: 0.78px;
  margin-bottom: 11px;
  font-weight: 600;
`

function ResouceType({ type }) {
  if (!type) {
    return
  }
  const text = resourceTypeProperNames[type] || ""
  if (!text) {
    return
  }
  return <div css={label}>{text}</div>
}

export default ResouceType
